<template>
  <div class="about">
    <Header></Header>
    <Breadcrumb :navTitle="'关于我们'"></Breadcrumb>
    <div class="about-content">
      <div class="content-title">
        <span>公司简介</span>
        <span>COMPANY INTRODUCTION</span>
      </div>
      <div v-html="itemObj.content"></div>
      <img :src="BaseUrl + itemObj.image" alt="" />
      <div>
        <vue-seamless-scroll
          :class-option="defaultOption"
          :data="itemObj.imglist"
          style="height: 190px; overflow: hidden"
        >
          <div v-for="(item, index) in itemObj.imglist" :key="index">
            <img :src="BaseUrl + item" alt="" />
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "About",
  data() {
    return {
      itemObj: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        url: "/about/index",
      }).then((res) => {
        console.log(res, "about");
        this.itemObj = res.data;
      });
    },
  },
  computed: {
    defaultOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  components: {
    Header,
    Breadcrumb,
    vueSeamlessScroll,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.about {
  .about-content {
    width: 1200px;
    margin: 0 auto;
    .content-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 1200px;
      margin-top: 50px;
      margin-bottom: 39px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 50px;
      > span:nth-child(1) {
        font-size: 30px;
        margin-bottom: 20px;
        font-weight: bold;
        color: #333333;
      }
      > span:nth-child(2) {
        font-size: 12px;
        color: #333333;
      }
    }
    > div:nth-child(2) {
      margin-bottom: 69px;
      font-size: 18px;
      line-height: 30px;
      color: #333333;
    }
    > img:nth-child(3) {
      width: 800px;
      height: 458px;
      margin-left: 50%;
      margin-bottom: 125px;
      transform: translate(-50%);
    }
    > div:nth-child(4) {
      width: 1200px;
      margin: 0 auto 105px;
      overflow: hidden;

      // display: flex;
      // flex-wrap: nowrap;
      img {
        width: 318px;
        height: 190px;
        margin-right: 18px;
      }
    }
  }
}
</style>
